<template>
  <div class="share-service-modal">
    <b-modal
      :visible="show"
      centered
      @change="toggleModal"
      @shown="openedModal"
    >
      <template v-slot:modal-header>
        <div>
          <p class="mb-0">Enlace para compartir servicio</p>
        </div>
      </template>
      <div class="tracking-url">
        <span>Todos los que tengan esta URL pueden ver tu servicio</span>
        <p
          v-tooltip="{
            content: 'Copiar',
            placement: 'top-center'
          }"
          class="url-link mt-4"
          @click="copyToClipboard"
        >
          {{ trackingUrl }}
        </p>
      </div>
      <template v-slot:modal-footer>
        <div>
          <z-button variant="primary" @click="toggleModal(false)">
            Aceptar
          </z-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
const Clipboardy = require("clipboardy");

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    serviceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      trackingUrl: ""
    };
  },
  methods: {
    openedModal() {
      this.getTrackingUrl();
    },
    toggleModal(show) {
      this.$emit("update:show", show);
    },
    getTrackingUrl() {
      this.trackingUrl = `${process.env.VUE_APP_WEB_APP_URL}/tracking/service/${this.serviceId}`;
      this.copyToClipboard();
    },
    copyToClipboard() {
      Clipboardy.write(this.trackingUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.tracking-url {
  span {
    color: rgba($black, 0.4);
  }

  .url-link {
    cursor: pointer;
    overflow-x: scroll;
    white-space: nowrap;
  }
}
</style>
